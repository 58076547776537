import axios from "axios";
axios.defaults.withCredentials = true;

const Whitelist = {
  async get() {
    try {
      const response = await axios.get(process.env.REACT_APP_API_DOMAIN + "/api/whitelist/");
      let { success, status } = response.data;
      if (success) return { success: true, status: status };
      else return { success: false };
    } catch (error) {
      return { success: false };
    }
  },
  async send(name, birthdayday, birthdaymonth, birthdayyear, social, discord, questionpower, questionpainrp, questionmetagaming, questionfearrp, questionreason) {
    try {
      const response = await axios.post(process.env.REACT_APP_API_DOMAIN + "/api/whitelist/", {
        name: name,
        birthdayday: birthdayday,
        birthdaymonth: birthdaymonth,
        birthdayyear: birthdayyear,
        social: social,
        discord: discord,
        questionpower: questionpower,
        questionpainrp: questionpainrp,
        questionmetagaming: questionmetagaming,
        questionfearrp: questionfearrp,
        questionreason: questionreason,
      });

      let { success } = response.data;
      console.log(response.data);
      if (success) return true;
      else return false;
    } catch (error) {
      return false;
    }
  },
  async list(page = 0, name) {
    try {
      const response = await axios.get(process.env.REACT_APP_API_DOMAIN + "/api/whitelist/list/" + name + "/" + page);
      let { success, list, total, totalAccepted, totalDeclined } = response.data;
      if (success) return { success: true, list: list, total: total, totalAccepted: totalAccepted, totalDeclined: totalDeclined };
      else return { success: false };
    } catch (error) {
      return { success: false };
    }
  },

  async accept(id) {
    try {
      const response = await axios.get(process.env.REACT_APP_API_DOMAIN + "/api/whitelist/approve/" + id);
      let { success } = response.data;
      if (success) return true;
      else return false;
    } catch (error) {
      return false;
    }
  },

  async decline(id) {
    try {
      const response = await axios.get(process.env.REACT_APP_API_DOMAIN + "/api/whitelist/decline/" + id);
      let { success } = response.data;
      if (success) return true;
      else return false;
    } catch (error) {
      return false;
    }
  },

  async remove(id) {
    try {
      const response = await axios.get(process.env.REACT_APP_API_DOMAIN + "/api/whitelist/remove/" + id);
      let { success } = response.data;
      if (success) return true;
      else return false;
    } catch (error) {
      return false;
    }
  },
};

export default Whitelist;
