import React from "react";

import Menu from "../Menu";
import Logo from "../Logo";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Login
    };
  }

  componentDidMount() {
    // Login user
  }

  steamLogin() {
    window.location.href = process.env.REACT_APP_AUTH_DOMAIN + "/auth/steam";
  }

  render() {
    return (
      <div>
        <Logo />
        <div className="container">
          <Menu page={this.props.page} isLoggedIn={this.props.isLoggedIn} />

          <div className="main">
            <div className="home">
              <div className="tab">
                <div className="login-text">
                  <div className="title">
                    <h4>Bem vindo!</h4>
                  </div>
                  {!this.props.isLoggedIn && !this.props.loading && <div className="text">Faz login para teres acesso a mais funcionalidades</div>}
                  {this.props.isLoggedIn && <div className="text">{this.props.user.displayname}</div>}
                </div>

                {this.props.isLoggedIn && !this.props.loading && (
                  <div className="login">
                    <button onClick={this.props.onLogout}> Logout</button>
                  </div>
                )}

                {!this.props.isLoggedIn && !this.props.loading && (
                  <div className="login">
                    <img onClick={this.steamLogin} height="50" alt="Steam Login" src="/img/steamlogin.png"></img>
                  </div>
                )}
              </div>

              {this.props.isLoggedIn && (
                <div className="tab">
                  <div style={{ float: "left" }}>
                    <div className="title">
                      <h4>Servidor</h4>
                    </div>
                    <div className="text">
                      Clica aqui para entrar:{" "}
                      <b>
                        <a rel="noopener noreferrer" target="_blank" href="fivem://connect/fivem.offset.gg">
                          fivem.offset.gg
                        </a>
                      </b>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
