import React from "react";

import Menu from "../Menu";
import Logo from "../Logo";

import StoreAPI from "../../helpers/API/Store";

class AdminSales extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      purchases: [],
      page: 0,
      loading: false,
    };

    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.getPurchases();
  }

  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.updateInterval);
  }

  async getPurchases() {
    if (this.state.loading) return;

    this.setState({ purchases: [] });

    let purchases = await StoreAPI.getAdminPurchases(this.state.page);
    if (!this.mounted) return;
    if (purchases.success) {
      this.setState({ purchases: purchases.purchases, loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  nextPage() {
    let page = this.state.page + 1;

    this.setState({ page: page });
    this.getPurchases(page);
  }

  prevPage() {
    let page = this.state.page - 1;
    if (page < 0) return;

    this.setState({ page: page });
    this.getPurchases(page);
  }

  render() {
    let hasNextPage = this.state.purchases.length > 30;
    let size = 30;

    let purchases = this.state.purchases.slice(0, size).map((i, index) => {
      return (
        <tr key={i.id}>
          <td className="ptop-table">{i.name}</td>
          <td className="ptop-table">{i.price} EUR</td>
          {i.state === "paid" && <td className="text-success ptop-table">Pago</td>}
          {i.state === "pending" && <td className="text-danger ptop-table">Aguardar pagamento</td>}
          {i.state === "expired" && <td className="ptop-table">Expirada</td>}
          <td className="ptop-table">{new Date(i.created).toLocaleString()}</td>
          {!i.received && <td className="ptop-table">Não</td>}
          {i.received && <td className="text-success ptop-table">Sim</td>}
        </tr>
      );
    });

    return (
      <div>
        <Logo />
        <div className="container">
          <Menu page={this.props.page} isLoggedIn={this.props.isLoggedIn} />

          <div className="main">
            <center>
              <small>Problemas com o pagamento? Envia email para: joao.arantes@offset-esports.com</small>
            </center>
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Produto</th>
                    <th scope="col">Preço</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Data</th>
                    <th scope="col">Ativo</th>
                  </tr>
                </thead>
                <tbody>{purchases}</tbody>
              </table>
            </div>

            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                {this.state.page > 0 && (
                  <li className="page-item">
                    <button disabled={this.state.loading} onClick={this.prevPage} className="page-link" tabIndex="-1">
                      Previous
                    </button>
                  </li>
                )}
                {hasNextPage && (
                  <li className="page-item">
                    <button disabled={this.state.loading} onClick={this.nextPage} className="page-link">
                      Next
                    </button>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
export default AdminSales;
