import React from "react";

import Menu from "../Menu";
import Logo from "../Logo";

import StoreAPI from "../../helpers/API/Store";

class MySales extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      purchases: [],
    };

    this.updateInterval = null;
  }

  componentDidMount() {
    this.mounted = true;
    this.getPurchases();

    this.updateInterval = setInterval(
      function () {
        this.getPurchases();
      }.bind(this),
      7000
    );
  }

  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.updateInterval);
  }

  async getPurchases() {
    let purchases = await StoreAPI.getPurchases();
    if (!this.mounted) return;
    if (purchases.success) {
      this.setState({ purchases: purchases.purchases });
    }
  }

  render() {
    let purchases = this.state.purchases.map((i, index) => {
      return (
        <tr key={i.id}>
          <th className="ptop-table" scope="row">
            {index + 1}
          </th>
          <td className="ptop-table">{i.name}</td>
          <td className="ptop-table">{i.price} EUR</td>
          {i.state === "paid" && <td className="text-success ptop-table">Pago</td>}
          {i.state === "pending" && <td className="text-danger ptop-table">Aguardar pagamento</td>}
          {i.state === "expired" && <td className="ptop-table">Expirada</td>}
          <td className="ptop-table">{new Date(i.created).toLocaleString()}</td>
          {!i.received && <td className="ptop-table">Não</td>}
          {i.received && <td className="text-success ptop-table">Sim</td>}
        </tr>
      );
    });

    purchases.reverse();

    return (
      <div>
        <Logo />
        <div className="container">
          <Menu page={this.props.page} isLoggedIn={this.props.isLoggedIn} />

          <div className="main">
            <center>
              <small>
                Problemas com o pagamento? Envia email para: joao.arantes@offset-esports.com
                <br></br>
                Nota: Para receber os <b>KM</b> aguarde <b>6min fora da fila</b> de espera.
              </small>
            </center>
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Produto</th>
                    <th scope="col">Preço</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Data</th>
                    <th scope="col">Ativo</th>
                  </tr>
                </thead>
                <tbody>{purchases}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MySales;
