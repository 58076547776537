import React from "react";
import { Redirect } from "react-router-dom";

import Main from "./components/Home";
import Whitelist from "./components/Whitelist";
import Loja from "./components/Loja";
import WhitelistAdmin from "./components/Admin/whitelist";
import MySales from "./components/Loja/mysales";
import ComprasAdmin from "./components/Loja/adminsales";
import AdminSales from "./components/Admin/sales";
import HowToPlay from "./components/HowToPlay";

import AccountAPI from "./helpers/API/Account";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Login
      isLoggedIn: false,
      loading: true,
      user: {},
    };

    this.onLogout = this.onLogout.bind(this);
  }

  async componentDidMount() {
    let user = await AccountAPI.get();
    if (user) this.setState({ isLoggedIn: true, user: user, loading: false });
    else this.setState({ isLoggedIn: false, user: {}, loading: false });
  }

  async onLogout() {
    let success = await AccountAPI.logout();
    if (success) {
      this.setState({ isLoggedIn: false, user: {} });
    }
  }

  render() {
    let content;
    switch (this.props.match.params.page) {
      case "home":
        content = <Main isLoggedIn={this.state.isLoggedIn} loading={this.state.loading} user={this.state.user} page="home" onLogout={this.onLogout} />;
        break;
      case "whitelist":
        content = <Whitelist isLoggedIn={this.state.isLoggedIn} user={this.state.user} page="whitelist" />;
        break;
      case "doacoes":
        content = <Redirect to="/loja" />;
        break;
      case "loja":
        content = <Loja isLoggedIn={this.state.isLoggedIn} user={this.state.user} page="loja" />;
        break;
      case "minhasdoacoes":
        content = <Redirect to="/minhascompras" />;
        break;
      case "minhascompras":
        content = <MySales isLoggedIn={this.state.isLoggedIn} user={this.state.user} page="minhascompras" />;
        break;
      case "whitelistadmin":
        content = <WhitelistAdmin isLoggedIn={this.state.isLoggedIn} user={this.state.user} page="whitelistadmin" />;
        break;
      case "comprasadmin":
        content = <ComprasAdmin isLoggedIn={this.state.isLoggedIn} user={this.state.user} page="comprasadmin" />;
        break;
      case "adminsales":
        content = <AdminSales isLoggedIn={this.state.isLoggedIn} user={this.state.user} page="adminsales" />;
        break;
      case "comojogar":
        content = <HowToPlay isLoggedIn={this.state.isLoggedIn} user={this.state.user} page="comojogar" />;
        break;
      default:
        content = <Redirect to="/404" />;
    }

    return <div>{content}</div>;
  }
}

export default Home;
