import React from "react";

import Menu from "../Menu";
import Logo from "../Logo";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Login
    };
  }

  componentDidMount() {
    // Login user
  }

  render() {
    return (
      <div>
        <Logo />
        <div className="container">
          <Menu page={this.props.page} isLoggedIn={this.props.isLoggedIn} />
          <div className="main">
            <div className="home">
              <div className="tab">
                <div style={{ float: "left" }}>
                  <div className="title" style={{ paddingTop: "10px" }}>
                    <h4>Como jogar no servidor?</h4>
                    <div className="text"></div>
                  </div>
                </div>
              </div>

              <div className="tab">
                <div style={{ float: "left" }}>
                  <div className="title">
                    <div className="text" style={{ fontWeight: 500 }}>
                      <br></br>1 - Criar conta steam:{" "}
                      <a rel="noopener noreferrer" target="_blank" href="https://steamcommunity.com/">
                        https://steamcommunity.com
                      </a>
                      <br></br>
                      <hr></hr>2 - Comprar e instalar o GTA5;<br></br>
                      <hr></hr>3 - Instalar o FIVEM:{" "}
                      <a rel="noopener noreferrer" target="_blank" href="https://fivem.net">
                        https://fivem.net
                      </a>
                      <br></br>
                      <hr></hr>4 - Entrar no discord do servidor:{" "}
                      <a rel="noopener noreferrer" target="_blank" href="https://discord.offset.gg">
                        https://discord.offset.gg
                      </a>
                      <br></br>
                      <hr></hr>5 - Dentro da aplicação FIVEM, abrir a consola no <b>F8</b> e inserir o IP: <b>connect fivem.offset.gg</b>
                      <br></br>
                      <br></br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
