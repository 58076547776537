import React from "react";
import { Link } from "react-router-dom";

import Menu from "../Menu";
import WhitelistAPI from "../../helpers/API/Whitelist";
import Logo from "../Logo";

class Whitelist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showForm: false,
      showSuccess: false,
      apiError: false,

      showDeclined: false,
      showClosed: false,
      showPending: false,
      showApproved: false,

      // Name
      name: "",
      // Birthday
      bday: "1",
      bmonth: "Janeiro",
      byear: "1920",
      social: "",
      discord: "",
      powerGaming: "",
      painRP: "",
      metaGaming: "",
      fearRP: "",
      reason: "",

      rules: false,

      // Error
      nameError: false,
      bdayError: false,
      bmonthError: false,
      byearError: false,
      socialError: false,
      discordError: false,
      powerGamingError: false,
      painRPError: false,
      metaGamingError: false,
      fearRPError: false,
      reasonError: false,
      rulesError: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onRulesClick = this.onRulesClick.bind(this);
    this.sendForm = this.sendForm.bind(this);
    this.isFormReady = this.isFormReady.bind(this);
  }

  async componentDidMount() {
    this.mouted = true;

    let whitelist = await WhitelistAPI.get();
    if (!this.mouted) return;

    if (whitelist.success) {
      if (whitelist.status === "allowed") this.setState({ showForm: true });
      if (whitelist.status === "pending") this.setState({ showPending: true });
      if (whitelist.status === "declined") this.setState({ showDeclined: true });
      if (whitelist.status === "closed") this.setState({ showClosed: true });
      if (whitelist.status === "approved") this.setState({ showApproved: true });
    }
  }

  componentWillUnmount() {
    this.mouted = false;
  }

  steamLogin() {
    window.location.href = process.env.REACT_APP_AUTH_DOMAIN + "/auth/steam";
  }

  handleChange(evt) {
    /* Validate empty inputs */
    let value = String(evt.target.value);
    //let valid = this.isInputValid(evt.target.name, value);
    // [evt.target.name + "Error"]: valid
    this.setState({ [evt.target.name]: value, [evt.target.name + "Error"]: false, apiError: false });
  }

  isInputValid(name, value) {
    let valid;

    if (name === undefined) return false;
    value = value.trim();

    switch (name) {
      case "name":
        valid = value && value.length < 50 ? true : false;
        break;
      case "bday":
        valid = value ? true : false;
        break;
      case "bmonth":
        valid = value ? true : false;
        break;
      case "byear":
        valid = value ? true : false;
        break;
      case "social":
        valid = value && value.length >= 10 && value.length <= 150 ? true : false;
        break;
      case "discord":
        valid = value && value.length >= 3 && value.length <= 100 ? true : false;
        break;
      case "powerGaming":
        valid = value && value.length >= 10 && value.length <= 300 ? true : false;
        break;
      case "painRP":
        valid = value && value.length >= 10 && value.length <= 300 ? true : false;
        break;
      case "metaGaming":
        valid = value && value.length >= 10 && value.length <= 300 ? true : false;
        break;
      case "fearRP":
        valid = value && value.length >= 10 && value.length <= 300 ? true : false;
        break;
      case "reason":
        valid = value && value.length >= 10 && value.length <= 2000 ? true : false;
        break;
      default:
        valid = true;
    }

    return valid;
  }

  onRulesClick(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ rules: value });
  }

  async sendForm() {
    if (this.state.loading) return;
    if (!this.isFormReady()) return;

    this.setState({ loading: true });

    let success = await WhitelistAPI.send(
      this.state.name,
      this.state.bday,
      this.state.bmonth,
      this.state.byear,
      this.state.social,
      this.state.discord,
      this.state.powerGaming,
      this.state.painRP,
      this.state.metaGaming,
      this.state.fearRP,
      this.state.reason
    );

    if (!this.mouted) return;

    if (success) {
      this.setState({ loading: false, showSuccess: true, showForm: false });
    } else {
      this.setState({ loading: false, apiError: true });
    }
  }

  isFormReady() {
    let nameValid = this.isInputValid("name", this.state.name);
    let discordVald = this.isInputValid("discord", this.state.discord);
    let powerGamingValid = this.isInputValid("powerGaming", this.state.powerGaming);
    let painGamingValid = this.isInputValid("painRP", this.state.painRP);
    let metaGamingValid = this.isInputValid("metaGaming", this.state.metaGaming);
    let fearRPValid = this.isInputValid("fearRP", this.state.fearRP);
    let reasonValid = this.isInputValid("reason", this.state.reason);
    let rulesValid = this.state.rules;

    if (nameValid && discordVald && powerGamingValid && painGamingValid && metaGamingValid && fearRPValid && reasonValid && rulesValid) {
      this.setState({
        nameError: false,
        discordError: false,
        powerGamingError: false,
        painRPError: false,
        metaGamingError: false,
        fearRPError: false,
        reasonError: false,
        rulesError: false,
        apiError: false,
      });

      return true;
    } else {
      this.setState({
        nameError: !nameValid,
        discordError: !discordVald,
        powerGamingError: !powerGamingValid,
        painRPError: !painGamingValid,
        metaGamingError: !metaGamingValid,
        fearRPError: !fearRPValid,
        reasonError: !reasonValid,
        rulesError: !rulesValid,
      });

      return false;
    }
  }

  render() {
    let days = Array.from({ length: 31 }, (_, i) => i + 1);
    if (this.state.bmonth === "Fevereiro") days = Array.from({ length: 29 }, (_, i) => i + 1);
    if (this.state.bmonth === "Abril") days = Array.from({ length: 30 }, (_, i) => i + 1);
    if (this.state.bmonth === "Junho") days = Array.from({ length: 30 }, (_, i) => i + 1);
    if (this.state.bmonth === "Setembro") days = Array.from({ length: 30 }, (_, i) => i + 1);
    if (this.state.bmonth === "Novembro") days = Array.from({ length: 30 }, (_, i) => i + 1);

    let months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
    let years = range(1920, 2018);

    let daysList = days.map((day, key) => <option key={key}>{day}</option>);
    let monthList = months.map((month, key) => <option key={key}>{month}</option>);
    let yearList = years.map((year, key) => <option key={key}>{year}</option>);

    function range(min, max) {
      var len = max - min + 1;
      var arr = new Array(len);
      for (var i = 0; i < len; i++) {
        arr[i] = min + i;
      }
      return arr;
    }

    return (
      <div>
        <Logo />
        <div className="container">
          <Menu page={this.props.page} isLoggedIn={this.props.isLoggedIn} />

          <div className="main">
            <div className="whitelist">
              {this.state.showSuccess && (
                <div id="whitelistApproved">
                  <div className="successNotification">
                    <div className="title">Boa sorte!</div>
                    <div className="subtitle">Formulário enviado com sucesso</div>
                  </div>

                  <div className="send">
                    <Link to="/" type="submit" className="btn btn-light">
                      Voltar
                    </Link>
                  </div>
                </div>
              )}

              {this.state.showClosed && (
                <div id="whitelistApproved">
                  <div className="successNotification">
                    <div className="title">Whitelist fechada!</div>
                    <div className="subtitle">De momento não estamos a aceitar mais candidaturas</div>
                  </div>

                  <div className="send">
                    <Link to="/" type="submit" className="btn btn-light">
                      Voltar
                    </Link>
                  </div>
                </div>
              )}

              {this.state.showDeclined && (
                <div id="whitelistApproved">
                  <div className="successNotification">
                    <div className="title">Não aprovado!</div>
                    <div className="subtitle">Tente novamente dentro de uma semana</div>
                  </div>

                  <div className="send">
                    <Link to="/" type="submit" className="btn btn-light">
                      Voltar
                    </Link>
                  </div>
                </div>
              )}

              {this.state.showPending && (
                <div id="whitelistApproved">
                  <div className="successNotification">
                    <div className="title">Em análise!</div>
                    <div className="subtitle">A sua candidatura encontra-se em análise. Boa sorte!</div>
                  </div>

                  <div className="send">
                    <Link to="/" type="submit" className="btn btn-light">
                      Voltar
                    </Link>
                  </div>
                </div>
              )}

              {this.state.showApproved && (
                <div id="whitelistApproved">
                  <div className="successNotification">
                    <div className="title">Aprovado!</div>
                    <div className="subtitle">Parabéns foi aceite na whitelist do servidor.</div>
                  </div>

                  <div className="send">
                    <Link to="/" type="submit" className="btn btn-light">
                      Voltar
                    </Link>
                  </div>
                </div>
              )}

              {this.state.showForm && (
                <div id="whitelistNew">
                  <div className="tab">
                    <div className="text">Preencha corretamente o formulário abaixo. O preenchimento do mesmo não garante a sua aprovação.</div>
                  </div>

                  <div className="formWhitelist">
                    <div className="form-group">
                      <label htmlFor="inputName">Nome:</label>

                      <input
                        onChange={this.handleChange}
                        name="name"
                        maxLength="100"
                        type="text"
                        className={"form-control" + (this.state.nameError ? " is-invalid" : "")}
                        id="name"
                        aria-describedby="name"
                        placeholder=""
                      />
                      <small id="nameHelp" className="form-text text-muted">
                        *Nome verdadeiro
                      </small>
                    </div>

                    <label htmlFor="birthdayLabel">Data de nascimento:</label>
                    <div className="form-inline">
                      <select onChange={this.handleChange} name="bday" className="form-control mb-2 mr-sm-2" id="bday">
                        {daysList}
                      </select>
                      <select onChange={this.handleChange} name="bmonth" className="form-control mb-2 mr-sm-2" id="bmonth">
                        {monthList}
                      </select>
                      <select onChange={this.handleChange} name="byear" className="form-control mb-2 mr-sm-2" id="byear">
                        {yearList}
                      </select>
                    </div>
                    <small id="nameHelp" className="form-text text-muted">
                      *Data verdadeira
                    </small>

                    <div className="form-group mt-3">
                      <label htmlFor="twitchInput">
                        Twitch / Youtube <small>(Opcional):</small>
                      </label>
                      <input
                        onChange={this.handleChange}
                        name="social"
                        maxLength="100"
                        type="text"
                        className={"form-control" + (this.state.socialError ? " is-invalid" : "")}
                        id="social"
                        aria-describedby="social"
                        placeholder=""
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="discordInput">Nome no discord ( com o # ): </label>
                      <input
                        onChange={this.handleChange}
                        name="discord"
                        maxLength="100"
                        type="text"
                        className={"form-control" + (this.state.discordError ? " is-invalid" : "")}
                        id="discord"
                        aria-describedby="discord"
                        placeholder=""
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="powerGamingInput">O que é PowerGaming?</label>
                      <input
                        onChange={this.handleChange}
                        name="powerGaming"
                        maxLength="300"
                        type="text"
                        className={"form-control" + (this.state.powerGamingError ? " is-invalid" : "")}
                        id="powerGaming"
                        aria-describedby="discord"
                        placeholder=""
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="painRPInput">O que é PainRP?</label>
                      <input
                        onChange={this.handleChange}
                        name="painRP"
                        maxLength="300"
                        type="text"
                        className={"form-control" + (this.state.painRPError ? " is-invalid" : "")}
                        id="painRP"
                        aria-describedby="discord"
                        placeholder=""
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="metaGamingInput">O que é MetaGaming?</label>
                      <input
                        onChange={this.handleChange}
                        name="metaGaming"
                        maxLength="300"
                        type="text"
                        className={"form-control" + (this.state.metaGamingError ? " is-invalid" : "")}
                        id="metaGaming"
                        aria-describedby="discord"
                        placeholder=""
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="fearRPInput">O que é FearRP?</label>
                      <input
                        onChange={this.handleChange}
                        name="fearRP"
                        maxLength="300"
                        type="text"
                        className={"form-control" + (this.state.fearRPError ? " is-invalid" : "")}
                        id="fearRP"
                        aria-describedby="discord"
                        placeholder=""
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="reasonTextArea">
                        Quais os teus objetivos na cidade? <small>(Elabore uma resposta original e criativa. Respostas simples não serão aceites)</small>
                      </label>
                      <textarea maxLength="1800" onChange={this.handleChange} name="reason" className={"form-control" + (this.state.reasonError ? " is-invalid" : "")} id="reason" rows="6"></textarea>
                    </div>

                    <div className="form-group form-check">
                      <input
                        checked={this.state.rules}
                        onChange={this.onRulesClick}
                        name="rules"
                        type="checkbox"
                        className={"form-check-input" + (this.state.rulesError ? " is-invalid" : "")}
                        id="rulesCheckbox"
                      />
                      <label name="rules" className="form-check-label" htmlFor="readRulesLabel">
                        Declaro que li, compreendi e aceito as{" "}
                        <a rel="noopener noreferrer" href="http://regras.offset.gg/" target="_blank">
                          Regras
                        </a>
                      </label>
                    </div>

                    <div className="send">
                      {this.state.apiError && <p className="text-danger">Ocorreu um erro. Tente novamente mais tarde.</p>}

                      {!this.state.loading && (
                        <button disabled={this.state.loading} onClick={this.sendForm} type="submit" className="btn btn-primary">
                          Enviar
                        </button>
                      )}
                      {this.state.loading && (
                        <div className="spinner-border text-primary" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Whitelist;
