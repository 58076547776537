import React from "react";

import Menu from "../Menu";
import Logo from "../Logo";
import WhitelistAPI from "../../helpers/API/Whitelist";

class AdminWhitelist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      modal: {
        id: "",
        name: "",
        steamurl: "",
        birthday: "",
        social: "",
        discord: "",
        powerGaming: "",
        painRP: "",
        metaGaming: "",
        fearRP: "",
        reason: "",
      },
      page: 0,
      total: 0,
      totalAccepted: 0,
      totalDeclined: 0,

      listSelected: "pending",
      loading: false,
    };

    this.updateModal = this.updateModal.bind(this);
    this.updateList = this.updateList.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.accept = this.accept.bind(this);
    this.decline = this.decline.bind(this);
    this.removeList = this.removeList.bind(this);
    this.remove = this.remove.bind(this);
    this.changeListSelected = this.changeListSelected.bind(this);
  }

  componentDidMount() {
    this.mouted = true;
    this.updateList(0);
  }

  async updateList(page) {
    if (this.state.loading) return;

    this.setState({ loading: true });
    let list = await WhitelistAPI.list(page, this.state.listSelected);
    if (!this.mouted) return;
    if (list.success) {
      this.setState({ list: list.list, total: list.total, totalAccepted: list.totalAccepted, totalDeclined: list.totalDeclined });
    }

    this.setState({ loading: false });
  }

  changeListSelected(name) {
    if (this.state.loading) return;
    this.setState(
      { listSelected: name, list: [] },
      function () {
        this.updateList(0, name);
      }.bind(this)
    );
  }

  removeList(id) {
    let newList = [];
    for (let item of this.state.list) {
      if (item._id !== id) {
        newList.push(item);
      }
    }
    this.setState({ list: newList });
  }

  componentWillUnmount() {
    this.mouted = false;
  }

  updateModal(id) {
    for (let item of this.state.list) {
      if (item._id === id) {
        this.setState({
          modal: {
            id: item._id,
            name: item.name,
            steamurl: item.userid.steamcommunity.profileurl,
            birthday: item.birthday.day + " / " + item.birthday.month + " / " + item.birthday.year,
            social: item.social ? item.social : "",
            discord: item.discord,
            powerGaming: item.questions.powerGaming,
            painRP: item.questions.painRP,
            metaGaming: item.questions.metaGaming,
            fearRP: item.questions.fearRP,
            reason: item.questions.reason,
          },
        });
        break;
      }
    }
  }

  async accept(id) {
    let success = await WhitelistAPI.accept(id);
    if (success) {
      this.removeList(id);
    }
  }

  async decline(id) {
    let success = await WhitelistAPI.decline(id);
    if (success) {
      this.removeList(id);
    }
  }

  async remove(id) {
    let success = await WhitelistAPI.remove(id);
    if (success) {
      this.removeList(id);
    }
  }

  nextPage() {
    let page = this.state.page + 1;
    this.setState({ page: page });
    this.updateList(page);
  }

  prevPage() {
    let page = this.state.page - 1;
    if (page < 0) return;
    this.setState({ page: page });
    this.updateList(page);
  }

  render() {
    let size = 30;
    let list = [];
    if (this.state.list.length > 0) {
      list = this.state.list.slice(0, size).map((i) => {
        return (
          <tr key={i._id}>
            <td>{i.name}</td>
            <td>{i.discord}</td>
            <td>{new Date(i.created).toLocaleString()}</td>
            <td>
              <button onClick={() => this.updateModal(i._id)} type="button" data-toggle="modal" data-target="#whitelistModal" className="btn btn-dark">
                Abrir
              </button>
            </td>
          </tr>
        );
      });
    }

    let hasNextPage = this.state.list.length > 30;

    return (
      <div>
        <Logo />
        <div className="container">
          <Menu page={this.props.page} isLoggedIn={this.props.isLoggedIn} />

          <div className="modal fade" id="whitelistModal" tabIndex="-1" role="dialog" aria-labelledby="whitelistLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Candidatura
                  </h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    <b>Nome:</b> {this.state.modal.name}
                  </p>
                  <p>
                    <b>Date de nascimento:</b> {this.state.modal.birthday}
                  </p>
                  <p>
                    <b>Steam:</b>{" "}
                    <a target="_blank" rel="noopener noreferrer" href={this.state.modal.steamurl}>
                      {this.state.modal.steamurl}
                    </a>
                  </p>
                  <hr></hr>
                  <p>
                    <b>Social:</b> {this.state.modal.social}
                  </p>
                  <p>
                    <b>Discord:</b> {this.state.modal.discord}
                  </p>
                  <hr></hr>
                  <p>
                    <b>O que é PowerGaming?</b>
                  </p>
                  <p>{this.state.modal.powerGaming}</p>
                  <p>
                    <b>O que é PainRP?</b>
                  </p>
                  <p>{this.state.modal.painRP}</p>
                  <p>
                    <b>O que é MetaGaming?</b>
                  </p>
                  <p>{this.state.modal.metaGaming}</p>
                  <p>
                    <b>O que é FearRP?</b>
                  </p>
                  <p>{this.state.modal.fearRP}</p>
                  <p>
                    <b>Quais os teus objetivos na cidade?</b>{" "}
                  </p>
                  <p>{this.state.modal.reason}</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">
                    Fechar
                  </button>
                  <button onClick={() => this.remove(this.state.modal.id)} type="button" className="btn btn-info" data-dismiss="modal">
                    Apagar
                  </button>
                  <button onClick={() => this.decline(this.state.modal.id)} type="button" className="btn btn-danger" data-dismiss="modal">
                    Recusar
                  </button>
                  <button onClick={() => this.accept(this.state.modal.id)} type="button" className="btn btn-primary" data-dismiss="modal">
                    Aceitar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="main">
            <div className="whitelistadmin">
              <div className="adminmenu">
                <div className="info">
                  Page: {this.state.page + 1}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Novos: {this.state.total}
                  &nbsp;&nbsp; - &nbsp;Aprovados: {this.state.totalAccepted}
                  &nbsp;&nbsp; - &nbsp;Recusado: {this.state.totalDeclined}
                </div>

                <div className="linkmenu">
                  <div className="btn-group btn-group-toggle">
                    <label disabled={this.state.loading} onClick={() => this.changeListSelected("pending")} className={"btn btn-info" + (this.state.listSelected === "pending" ? " active" : "")}>
                      <input type="radio" name="options" /> Novos
                    </label>
                    <label disabled={this.state.loading} onClick={() => this.changeListSelected("approved")} className={"btn btn-info" + (this.state.listSelected === "approved" ? " active" : "")}>
                      <input type="radio" name="options" /> Ver aprovados
                    </label>
                    <label disabled={this.state.loading} onClick={() => this.changeListSelected("declined")} className={"btn btn-info" + (this.state.listSelected === "declined" ? " active" : "")}>
                      <input type="radio" name="options" /> Ver recusados
                    </label>
                  </div>
                </div>
              </div>
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Discord</th>
                    <th scope="col">Created</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>{list}</tbody>
              </table>

              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  {this.state.page > 0 && (
                    <li className="page-item">
                      <button disabled={this.state.loading} onClick={this.prevPage} className="page-link" tabIndex="-1">
                        Previous
                      </button>
                    </li>
                  )}
                  {hasNextPage && (
                    <li className="page-item">
                      <button disabled={this.state.loading} onClick={this.nextPage} className="page-link">
                        Next
                      </button>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminWhitelist;
