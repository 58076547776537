import React from "react";
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";

import Home from "./Home";
import NotFound from "./NotFound";

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />;
          </Route>
          <Route exact path="/:page" component={Home} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
