import React from "react";
import { Link } from "react-router-dom";

function Menu(props) {
  return (
    <nav className="circle">
      <ul>
        <li>
          <Link to="/home" className={props.page === "home" ? "menu--active" : ""}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/comojogar" className={props.page === "comojogar" ? "menu--active" : ""}>
            Como jogar?
          </Link>
        </li>
        {/*
        <li>
          {props.isLoggedIn ? (
            <Link to="/whitelist" className={props.page === "whitelist" ? "menu--active" : ""}>
              PEDIR WHITELIST
            </Link>
          ) : (
            <Link to={{ pathname: process.env.REACT_APP_AUTH_DOMAIN + "/auth/steam" }} target="_blank" className={props.page === "whitelist" ? "menu--active" : ""}>
              PEDIR WHITELIST
            </Link>
          )}
        </li>
        */}
        <li>
          <Link to="/loja" className={props.page === "loja" ? "menu--active" : ""}>
            LOJA
          </Link>
        </li>
        <li>
          <Link to={{ pathname: "https://discord.gg/Sttftsg" }} target="_blank">
            Discord
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Menu;
