import React from "react";
import { Link } from "react-router-dom";

import Menu from "../Menu";
import Logo from "../Logo";

import StoreAPI from "../../helpers/API/Store";

class Loja extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sid: "",
      // ------------
      products: [],

      // Open sale
      activeSale: false,
      product: {},
      extra: [],
      name: "",
      email: "",
      nif: "",
      rules: false,
      payment: "",
      mbwayphone: "",

      // ------------
      rulesError: false,
      nameError: false,
      emailError: false,
      paymentError: false,
      mbwayphoneError: false,
      apiError: false,

      // ------------
      loading: false,
      maintenance: false,
      doingRedirectCheck: true,
    };

    this.buy = this.buy.bind(this);
    this.onRulesClick = this.onRulesClick.bind(this);
    this.cancel = this.cancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.paymentChange = this.paymentChange.bind(this);
    this.completePayment = this.completePayment.bind(this);
    this.addExtra = this.addExtra.bind(this);
    this.removeExtra = this.removeExtra.bind(this);
    this.steamLogin = this.steamLogin.bind(this);
  }

  async componentDidMount() {
    this.mounted = true;
    if (!this.state.maintenance) this.loadProducts();

    let getStore = await StoreAPI.getStore();
    if (!getStore.success) {
      window.location.href = "https://offset.tebex.io/";
    } else {
      this.setState({ doingRedirectCheck: false });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  steamLogin() {
    window.location.href = process.env.REACT_APP_AUTH_DOMAIN + "/auth/steam";
  }

  async loadProducts() {
    let products = await StoreAPI.getProducts();
    if (!this.mounted) return;
    if (products.success) {
      this.setState({ products: products.products, sid: products.sid });
    }
  }

  onRulesClick(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ rules: value });
  }

  buy(id) {
    if (this.state.activeSale) return;
    for (let product of this.state.products) {
      if (product.id === id) {
        this.setState({ product: product, activeSale: true });
        break;
      }
    }
  }

  getExtraGroup(name) {
    let extraGroup = "";
    for (let i of this.state.product.extra) {
      if (i.name === name) {
        extraGroup = i.group;
        break;
      }
    }

    return extraGroup;
  }

  addExtra(name) {
    if (this.loading) return;

    let group = this.getExtraGroup(name);

    for (let i of this.state.extra) {
      if (this.getExtraGroup(i) === group) return;
    }

    let newExtra = this.state.extra;
    newExtra.push(name);
    this.setState({ extra: newExtra });
  }

  removeExtra(name) {
    if (this.loading) return;
    let newExtra = [];
    for (let i of this.state.extra) {
      if (i !== name) newExtra.push(i);
    }
    this.setState({ extra: newExtra });
  }

  cancel() {
    this.setState({
      activeSale: false,
      email: "",
      name: "",
      nif: "",
      mbwayphone: "",
      payment: "",
      extra: [],
      rules: false,
      rulesError: false,
      nameError: false,
      emailError: false,
      paymentError: false,
      mbwayphoneError: false,
      apiError: false,
    });
  }

  handleChange(evt) {
    let value = String(evt.target.value);
    this.setState({ [evt.target.name]: value, [evt.target.name + "Error"]: false, apiError: false });
  }

  paymentChange(payment) {
    this.setState({ payment: payment, paymentError: false });
  }

  async completePayment() {
    if (this.state.loading) return;

    let nameValid = this.isInputValid("name", this.state.name);
    let emailValid = this.isInputValid("email", this.state.email);
    let rulesValid = this.state.rules;
    let paymentValid = this.state.payment === "mbway" || this.state.payment === "paysafecard" ? true : false;
    let mbwayphoneValid = this.isInputValid("mbwayphone", this.state.mbwayphone);

    let mbwayValid = this.state.payment !== "mbway" || (this.state.payment === "mbway" && mbwayphoneValid);

    if (nameValid && emailValid && rulesValid && paymentValid && mbwayValid) {
      this.setState({ loading: true, apiError: false, nameError: !nameValid, emailError: !emailValid, rulesError: !rulesValid, paymentError: !paymentValid, mbwayphoneError: !mbwayValid });
      // API call
      let purchase = await StoreAPI.purchase(this.state.sid, this.state.name, this.state.email, this.state.nif, this.state.product.id, this.state.payment, this.state.mbwayphone, this.state.extra);
      if (!this.mounted) return;
      if (purchase.success) {
        if (this.state.payment === "mbway") {
          window.location.href = "/minhascompras";
        } else {
          window.location.href = purchase.url;
        }
      } else {
        this.setState({ loading: false, apiError: true });
      }
    } else {
      this.setState({ apiError: false, nameError: !nameValid, emailError: !emailValid, rulesError: !rulesValid, paymentError: !paymentValid, mbwayphoneError: !mbwayValid });
    }
  }

  isInputValid(name, value) {
    let valid;

    if (name === undefined) return false;
    value = value.trim();

    switch (name) {
      case "name":
        valid = value && value.length < 100 && value.length > 3 ? true : false;
        break;
      case "email":
        valid = value && this.validateEmail(value) ? true : false;
        break;
      case "mbwayphone":
        valid = value && !isNaN(value) && value.length === 9 ? true : false;
        break;
      default:
        valid = true;
    }
    return valid;
  }

  render() {
    let productsVIP = [];
    let productsKM = [];
    let productsWhitelist = [];
    let productsORGTunning = [];
    let productsORGArmas = [];
    let productsORGDrogas = [];
    let productsORGLavagem = [];
    let productsOther = [];

    let productsExtraOrgs = [];
    let productsExtraPlayers = [];

    this.state.products.forEach(
      function (product) {
        if (product.group === "vip") {
          productsVIP.push(
            <div key={product.id} className="product">
              <div className="description">{product.description}</div>
              <div className="price">{product.price} EUR</div>
              <div className="buy">
                {product.available ? (
                  <button onClick={() => this.buy(product.id)} type="button" className="btn btn-primary btnBlue">
                    Comprar
                  </button>
                ) : (
                  <button disabled={true} type="button" className="btn btn-danger">
                    Esgotado
                  </button>
                )}
              </div>
            </div>
          );
        }

        if (product.group === "km") {
          productsKM.push(
            <div key={product.id} className="product">
              <div className="description">{product.description}</div>
              <div className="price">{product.price} EUR</div>
              <div className="buy">
                {product.available ? (
                  <button onClick={() => this.buy(product.id)} type="button" className="btn btn-primary btnBlue">
                    Comprar
                  </button>
                ) : (
                  <button disabled={true} type="button" className="btn btn-danger">
                    Esgotado
                  </button>
                )}
              </div>
            </div>
          );
        }

        if (product.group === "orgextra") {
          productsExtraOrgs.push(
            <div key={product.id} className="product">
              <div className="description">{product.description}</div>
              <div className="price">{product.price} EUR</div>
              <div className="buy">
                {product.available ? (
                  <button onClick={() => this.buy(product.id)} type="button" className="btn btn-primary btnBlue">
                    Comprar
                  </button>
                ) : (
                  <button disabled={true} type="button" className="btn btn-danger">
                    Esgotado
                  </button>
                )}
              </div>
            </div>
          );
        }

        if (product.group === "playerextra") {
          productsExtraPlayers.push(
            <div key={product.id} className="product">
              <div className="description">{product.description}</div>
              
              {product.description.includes("Coins") ? (
                <div className="price">
                  {product.price} EUR
                  {/*
                  <span style={{ color: "#23313c", fontWeight: "bolder", textDecoration: "line-through", paddingLeft: "10px" }}>{product.price * 2} EUR</span>
                  <span style={{ color: "#f44336", fontWeight: "bold", display: "block" }}>-50% Black Friday</span>
                  */}
                </div>
              ) : (
                <div className="price">{product.price} EUR</div>
              )}

              <div className="buy">
                {product.available ? (
                  <button onClick={() => this.buy(product.id)} type="button" className="btn btn-primary btnBlue">
                    Comprar
                  </button>
                ) : (
                  <button disabled={true} type="button" className="btn btn-danger">
                    Esgotado
                  </button>
                )}
              </div>
            </div>
          );
        }

        if (product.group === "orgtunning") {
          productsORGTunning.push(
            <div key={product.id} className="product">
              <div className="description">{product.description}</div>
              <div className="price">{product.price} EUR</div>
              <div className="buy">
                {product.available ? (
                  <button onClick={() => this.buy(product.id)} type="button" className="btn btn-primary btnBlue">
                    Comprar
                  </button>
                ) : (
                  <button disabled={true} type="button" className="btn btn-danger">
                    Esgotado
                  </button>
                )}
              </div>
            </div>
          );
        }

        if (product.group === "orgarmas") {
          productsORGArmas.push(
            <div key={product.id} className="product">
              <div className="description">{product.description}</div>
              <div className="price">{product.price} EUR</div>
              <div className="buy">
                {product.available ? (
                  <button onClick={() => this.buy(product.id)} type="button" className="btn btn-primary btnBlue">
                    Comprar
                  </button>
                ) : (
                  <button disabled={true} type="button" className="btn btn-danger">
                    Esgotado
                  </button>
                )}
              </div>
            </div>
          );
        }

        if (product.group === "orgdrogas") {
          productsORGDrogas.push(
            <div key={product.id} className="product">
              <div className="description">{product.description}</div>
              <div className="price">{product.price} EUR</div>
              <div className="buy">
                {product.available ? (
                  <button onClick={() => this.buy(product.id)} type="button" className="btn btn-primary btnBlue">
                    Comprar
                  </button>
                ) : (
                  <button disabled={true} type="button" className="btn btn-danger">
                    Esgotado
                  </button>
                )}
              </div>
            </div>
          );
        }

        if (product.group === "orglavagem") {
          productsORGLavagem.push(
            <div key={product.id} className="product">
              <div className="description">{product.description}</div>
              <div className="price">{product.price} EUR</div>
              <div className="buy">
                {product.available ? (
                  <button onClick={() => this.buy(product.id)} type="button" className="btn btn-primary btnBlue">
                    Comprar
                  </button>
                ) : (
                  <button disabled={true} type="button" className="btn btn-danger">
                    Esgotado
                  </button>
                )}
              </div>
            </div>
          );
        }

        if (product.group === "whitelist") {
          productsWhitelist.push(
            <div key={product.id} className="product">
              <div className="description">{product.description}</div>
              <div className="price">{product.price} EUR</div>
              <div className="buy">
                {product.available ? (
                  <button onClick={() => this.buy(product.id)} type="button" className="btn btn-primary btnBlue">
                    Comprar
                  </button>
                ) : (
                  <button disabled={true} type="button" className="btn btn-danger">
                    Esgotado
                  </button>
                )}
              </div>
            </div>
          );
        }

        if (product.group === "other") {
          productsOther.push(
            <div key={product.id} className="product">
              <div className="description">{product.description}</div>
              <div className="price">{product.price} EUR</div>
              <div className="buy">
                {product.available ? (
                  <button onClick={() => this.buy(product.id)} type="button" className="btn btn-primary btnBlue">
                    Comprar
                  </button>
                ) : (
                  <button disabled={true} type="button" className="btn btn-danger">
                    Esgotado
                  </button>
                )}
              </div>
            </div>
          );
        }
      }.bind(this)
    );

    let itemExtra = [];
    if (this.state.product && this.state.product.extra) {
      this.state.product.extra.forEach(
        function (extra, id) {
          let extraEnabled = this.state.extra.includes(extra.name);

          itemExtra.push(
            <div key={id} className="products">
              <div className="product">
                <div className="description">{extra.description}</div>
                <div className="price">+ {extra.price} EUR</div>
                <div className="buy">
                  {!extraEnabled ? (
                    <button disabled={this.state.loading} onClick={() => this.addExtra(extra.name)} type="button" className="btn btn-primary btnBlue">
                      Adicionar extra
                    </button>
                  ) : (
                    <button disabled={this.state.loading} onClick={() => this.removeExtra(extra.name)} type="button" className="btn btn-danger">
                      Remove extra
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        }.bind(this)
      );
    }

    return (
      <div>
        <Logo />
        <div className="container">
          <Menu page={this.props.page} isLoggedIn={this.props.isLoggedIn} />

          <div className="main">
            {this.state.doingRedirectCheck && (
              <div className="whitelist">
                <div id="whitelistApproved">
                  <div className="successNotification">
                    <div className="title">...</div>
                    <div className="subtitle"></div>
                  </div>

                  <div className="send">
                    <Link to="/" type="submit" className="btn btn-light">
                      Voltar
                    </Link>
                  </div>
                </div>
              </div>
            )}

            {!this.state.doingRedirectCheck && this.state.maintenance && (
              <div className="whitelist">
                <div id="whitelistApproved">
                  <div className="successNotification">
                    <div className="title">Em breve!</div>
                    <div className="subtitle">Temporariamente indisponível</div>
                  </div>

                  <div className="send">
                    <Link to="/" type="submit" className="btn btn-light">
                      Voltar
                    </Link>
                  </div>
                </div>
              </div>
            )}

            {!this.state.doingRedirectCheck && !this.state.maintenance && (
              <div className="loja">
                <div className="pricing-header px-3 py-3 mx-auto text-center">
                  <p className="lead"></p>
                </div>
                <center className="mysales">
                  <Link to="/minhascompras" type="button" className="btn btn-light">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-bag-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M8 1a2.5 2.5 0 0 0-2.5 2.5V4h5v-.5A2.5 2.5 0 0 0 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5H2z"
                      />
                      <path fillRule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                    </svg>
                    <div className="mysalesbtn">Minhas compras</div>
                  </Link>
                </center>

                {!this.state.activeSale && (
                  <div id="InfoShop">
                    {productsVIP.length > 0 && (
                      <div id="tabVIP">
                        <div className="tab">
                          <div className="text">VIP (Mensal)</div>
                        </div>
                        <div className="products marginProduct">{productsVIP}</div>
                      </div>
                    )}

                    {productsWhitelist.length > 0 && (
                      <div id="tabWhitelist">
                        <div className="tab">
                          <div className="text">Whitelist</div>
                        </div>
                        <div className="products marginProduct">{productsWhitelist}</div>
                      </div>
                    )}

                    {productsKM.length > 0 && (
                      <div id="tabKM">
                        <div className="tab">
                          <div className="text">Vantagem fila de espera (KM)</div>
                        </div>
                        <div className="products marginProduct">{productsKM}</div>
                      </div>
                    )}

                    {productsORGTunning.length > 0 && (
                      <div id="tabORG">
                        <div className="tab">
                          <div className="text">Organizações Tunning</div>
                        </div>
                        <div className="products">{productsORGTunning}</div>
                      </div>
                    )}

                    {productsORGArmas.length > 0 && (
                      <div id="tabORG">
                        <div className="tab">
                          <div className="text">Organizações Armas</div>
                        </div>
                        <div className="products">{productsORGArmas}</div>
                      </div>
                    )}

                    {productsORGDrogas.length > 0 && (
                      <div id="tabORG">
                        <div className="tab">
                          <div className="text">Organizações Drogas</div>
                        </div>
                        <div className="products">{productsORGDrogas}</div>
                      </div>
                    )}

                    {productsORGLavagem.length > 0 && (
                      <div id="tabORG">
                        <div className="tab">
                          <div className="text">Organizações Lavagem Dinheiro/Serviço de Segurança</div>
                        </div>
                        <div className="products">{productsORGLavagem}</div>
                      </div>
                    )}

                    {productsExtraPlayers.length > 0 && (
                      <div id="tabORG">
                        <div className="tab">
                          <div className="text">Extras Jogadores</div>
                          {/*<small className="text">Preço promocional válido de 20/11/2023 até 27/11/2023</small>*/}
                        </div>
                        <div className="products">{productsExtraPlayers}</div>
                      </div>
                    )}

                    {productsExtraOrgs.length > 0 && (
                      <div id="tabORG">
                        <div className="tab">
                          <div className="text">Extras Organizações</div>
                        </div>
                        <div className="products">{productsExtraOrgs}</div>
                      </div>
                    )}

                    {productsOther.length > 0 && (
                      <div id="tabORG">
                        <div className="tab">
                          <div className="text">Outras</div>
                        </div>
                        <div className="products">{productsOther}</div>
                      </div>
                    )}
                  </div>
                )}

                {this.state.activeSale && (
                  <div id="InfoComprar">
                    <div className="tab">
                      <div className="text">Compra</div>
                    </div>

                    <div className="products">
                      <div className="product">
                        <div className="description">{this.state.product.description}</div>
                        <div className="price">{this.state.product.price} EUR</div>
                        <div className="buy"></div>
                      </div>
                    </div>

                    <hr></hr>
                    {itemExtra.length > 0 && (
                      <div id="extraIems">
                        {itemExtra}
                        <hr></hr>
                      </div>
                    )}

                    <center>
                      <button style={{ marginBottom: "10px" }} disabled={this.state.loading} onClick={this.cancel} type="button" className="btn btn-light">
                        Cancelar compra
                      </button>
                    </center>

                    <div className="tab">
                      <div className="text">Informações</div>
                    </div>

                    <div className="formShop">
                      <div className="form-group mt-3">
                        <label htmlFor="nameInput">Nome</label>
                        <input
                          onChange={this.handleChange}
                          name="name"
                          maxLength="100"
                          type="text"
                          className={"form-control" + (this.state.nameError ? " is-invalid" : "")}
                          id="name"
                          aria-describedby="name"
                          placeholder=""
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="emailInput">Email</label>
                        <input
                          onChange={this.handleChange}
                          name="email"
                          maxLength="100"
                          type="text"
                          className={"form-control" + (this.state.emailError ? " is-invalid" : "")}
                          id="email"
                          aria-describedby="email"
                          placeholder=""
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="nifInput">
                          NIF <small>(Opcional)</small>
                        </label>
                        <input onChange={this.handleChange} name="nif" maxLength="50" type="text" className="form-control" id="nif" aria-describedby="nif" placeholder="" />
                      </div>

                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          onChange={() => this.paymentChange("mbway")}
                          type="radio"
                          className={"custom-control-input" + (this.state.paymentError ? " is-invalid" : "")}
                          id="customRadio"
                          name="example"
                          value="customEx"
                        ></input>
                        <label className="custom-control-label" htmlFor="customRadio">
                          MBWay
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          onChange={() => this.paymentChange("paysafecard")}
                          className={"custom-control-input" + (this.state.paymentError ? " is-invalid" : "")}
                          type="radio"
                          id="customRadio2"
                          name="example"
                          value="customEx"
                        ></input>
                        <label className="custom-control-label" htmlFor="customRadio2">
                          Paysafecard
                        </label>
                      </div>

                      {this.state.payment === "mbway" && (
                        <div>
                          <hr></hr>
                          <div className="form-group mt-3">
                            <label htmlFor="mbwayInput">Nº Telemovel (MBWay)</label>
                            <input
                              onChange={this.handleChange}
                              name="mbwayphone"
                              maxLength="9"
                              type="text"
                              className={"form-control" + (this.state.mbwayphoneError ? " is-invalid" : "")}
                              id="mbwayphone"
                              aria-describedby="mbwayphone"
                              placeholder=""
                            />
                          </div>

                          <br></br>
                        </div>
                      )}
                      {this.props.isLoggedIn ? (
                        <center>
                          {this.state.apiError && <p className="text-danger">Ocorreu um erro. Tente novamente mais tarde.</p>}
                          {!this.state.loading && (
                            <button disabled={this.state.loading} onClick={this.completePayment} type="button" className="btn btn-primary btn-lg">
                              Pagar
                            </button>
                          )}
                          {this.state.loading && (
                            <div className="spinner-border text-primary" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                          <div className="form-group form-check top-20">
                            <input
                              checked={this.state.rules}
                              onChange={this.onRulesClick}
                              name="rules"
                              type="checkbox"
                              className={"form-check-input" + (this.state.rulesError ? " is-invalid" : "")}
                              id="rulesCheckbox"
                            />
                            <label name="rules" className="form-check-label" htmlFor="readRulesLabel">
                              Declaro que li, compreendi e aceito as{" "}
                              <a rel="noopener noreferrer" href="http://regras.offset.gg/" target="_blank">
                                Regras
                              </a>
                            </label>
                          </div>
                        </center>
                      ) : (
                        <div className="login-store">
                          <img onClick={this.steamLogin} height="50" alt="Steam Login" src="/img/steamlogin.png"></img>
                        </div>
                      )}

                      <br></br>
                      <hr></hr>
                    </div>
                  </div>
                )}
                <center>
                  <img style={{ maxWidth: "440px", width: "100%" }} src="/img/checkoutmethods.png" alt="Payment methods"></img>
                  <br></br>
                  <small>IVA INCLUÍDO À TAXA EM VIGOR PARA TODOS OS PRODUTOS</small>
                </center>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  validateEmail(email) {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }
}

export default Loja;
