import React from "react";

function Logo(props) {
  return (
    <div className="logo">
      <img src="/img/logo.png?v1" alt="OFFSET-ROLEPLAY"></img>
    </div>
  );
}

export default Logo;
