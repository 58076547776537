import axios from "axios";
axios.defaults.withCredentials = true;

const Account = {
  async get() {
    try {
      const response = await axios.get(process.env.REACT_APP_API_DOMAIN + "/api/account/");
      let { success, user } = response.data;
      if (success) return user;
      else return null;
    } catch (error) {
      return null;
    }
  },
  async logout() {
    try {
      const response = await axios.get(process.env.REACT_APP_API_DOMAIN + "/api/account/logout");
      let { success } = response.data;
      return success;
    } catch (error) {
      return false;
    }
  },
};

export default Account;
