import React from "react";

import Logo from "../Logo";
import StoreAPI from "../../helpers/API/Store";

class Sales extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      purchases: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.getPurchases();
  }

  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.updateInterval);
  }

  async getPurchases() {
    if (this.state.loading) return;

    this.setState({ purchases: [] });

    let purchases = await StoreAPI.getAdminPurchasesV2();
    if (!this.mounted) return;
    if (purchases.success) {
      console.log(purchases.purchases.length);
      this.setState({ purchases: purchases.purchases, loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    let purchases = this.state.purchases.map((i, index) => {
      return (
        <tr key={i.id}>
          <td className="ptop-table">{index + 1}</td>
          <td className="ptop-table">{i.name}</td>
          <td className="ptop-table">{i.clientname}</td>
          <td className="ptop-table">{i.nif}</td>
          <td className="ptop-table">{i.payment}</td>
          <td className="ptop-table">{i.price} EUR</td>
          <td className="ptop-table">{i.email}</td>
          {i.state === "paid" && <td className="text-success ptop-table">Pago</td>}
          {i.state === "pending" && <td className="text-danger ptop-table">Aguardar pagamento</td>}
          {i.state === "expired" && <td className="ptop-table">Expirada</td>}
          <td className="ptop-table">{new Date(i.created).toLocaleString()}</td>
        </tr>
      );
    });

    return (
      <div>
        <Logo />

        <div className="main">
          <div className="table-responsive">
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Produto</th>
                  <th scope="col">Nome</th>
                  <th scope="col">NIF</th>
                  <th scope="col">Método</th>
                  <th scope="col">Preço</th>
                  <th scope="col">Email</th>
                  <th scope="col">Estado</th>
                  <th scope="col">Data</th>
                </tr>
              </thead>
              <tbody>{purchases}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
export default Sales;
